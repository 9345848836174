import axios from "axios";
import { Constants } from '../utils/Constants';

export function login(email, password) {
  return axios.post(`${Constants.url}/oauth/token`, {
    grant_type: "password",
    client_id: Constants.client_id,
    client_secret: Constants.client_secret,
    usu_tipo: "CLIENTE",
    username: email,
    password: password
  });
}

export function getUserByToken() {
  return axios.get(`${Constants.baseUrl}/getUsuario`);
}

export function logout() {
  return axios.get(`${Constants.baseUrl}/logout`);
}
