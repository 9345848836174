import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import BetterBox from '../../components/BetterBox';
import BetterAlerts from '../../components/BetterAlerts';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { update } from '../../crud/primeiroacesso.crud';
import { FormHelperText } from "@material-ui/core";
import { Formik } from "formik";
import { createFormikValidation } from '../../utils/Utils';
import { connect } from "react-redux";
import * as auth from "../../store/ducks/auth.duck";

class PrimeiroAcessoPage extends Component {
  constructor() {
    super();

    this.state = {
      data: null,
      loading: false,
      submitted: false,
      errors: [],
    };
  }

  componentDidMount = () => {
  };

  onSubmit = values => {
    this.setState(({ submitted: true }));

    update(values).then(res => {
      window.scrollTo(0, 0);

      if (res.data.errors) {
        this.setState({ submitted: false, errors: res.data.errors ? res.data.errors : [] });
      } else {
        this.props.setPrimeiroAcesso();
      }
    }).catch((e) => {
      alert("Parece que houve um problema ao tentar enviar o formulário. Por favor, entre em contato com o suporte.");
    });
  };

  validateForm = values => {
    let map = new Map();

    map.set('password', 'senha');

    return createFormikValidation(map, values);
  };

  render() {
    const formControlStyle = {
      borderRadius: '5px',
      height: '50px',
      fontWeight: '600',
      fontSize: '21px',
      textAlign: 'center',
      color: '#424242',
    };

    const helperStyle = {
      color: 'red',
      fontSize: '13px',
      textAlign: 'center',
      marginTop: '5px'
    };

    return (
      <div style={{ margin: '50px 0px 50px 0px' }}>
        <div className="row" style={{ margin: '0' }}>
          <div className="col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div className="row">
              <div className="col-sm-12">
                <center>
                  <img alt="Logo" src={toAbsoluteUrl("/images/logo-dark.png")} style={{ width: '250px', height: 'auto', marginTop: '35px', marginBottom: '35px' }} />

                  <p style={{ padding: '10px 50px 15px 50px', fontSize: '18px', color: '#424242', fontWeight: '500' }}>
                    <b>Olá!</b> Esse é o seu primeiro acesso no nosso portal do cliente. Antes de iniciarmos, precisamos que você escolha uma nova senha, essa será definitiva e você utilizará a partir do seu próximo acesso.
                  </p>
                </center>
              </div>

              <div className="col-sm-12">
                <BetterCircularProgress loading={this.state.loading}>
                  <div>
                    <div className="col-sm-12">
                      <BetterAlerts errors={this.state.errors} />
                    </div>

                    <div>
                      <Formik initialValues={{
                        password: '',
                      }} validate={values => this.validateForm(values)} onSubmit={(values) => this.onSubmit(values)}>
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                          <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>

                            <div className="col-sm-12">
                              <BetterBox title="Escolha uma senha." subtitle="Você não pode utilizar a mesma senha que foi enviada para seu email. Sua nova senha precisa ter no mínimo 6 caracteres.">
                                <div className="col-sm-12">
                                  <div className='form-group fg-line'>
                                    <input type="password" name="password" className="form-control" style={formControlStyle} placeholder="Escolha uma senha"
                                      onChange={handleChange} value={values.password} />

                                    {(touched.password && errors.password) ?
                                      <FormHelperText style={helperStyle}>{errors.password}</FormHelperText>
                                      : null
                                    }
                                  </div>
                                </div>
                              </BetterBox>
                            </div>

                            <div className="col-sm-12">
                              <center>
                                <br />

                                <button type="submit" disabled={this.state.submitted} className="btn btn-primary btn-lg btn-bold">
                                  <i className={`margin-icon ${this.state.submitted ? "fas fa-sync fa-spin" : "fa fa-check-square"}`}></i>Começar!
                                  </button>

                                <br /><br />

                                <Link to="/logout" className="btn btn-label-brand btn-sm btn-bold">Sair</Link>
                              </center>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </BetterCircularProgress>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, auth.actions)(PrimeiroAcessoPage)