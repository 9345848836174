
export default {
    header: {
        self: {},
        items: []
    },
    aside: {
        self: {},
        items: [
            {
                title: "Minhas viagens",
                root: true,
                icon: "fas fa-dolly",
                page: "viagem",
                bullet: "dot"
            },
            {
                title: "Minhas faturas",
                root: true,
                icon: "fas fa-money-check-alt",
                page: "fatura",
                bullet: "dot"
            },
            {
                title: "Meus containers",
                root: true,
                icon: "fas fa-box",
                page: "container",
                bullet: "dot"
            },
        ]
    }
};
