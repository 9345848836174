
export const formatBRLInput = input => {
  input.target.value = Number(input.target.value.toString().replace(/[^0-9-]/g, '')) / 10 ** 2;

  input.target.value = formatBRL(input.target.value);

  return input;
};

export const formatBRL = value => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  });

  value = formatter.format(value);

  return value;
};

export const createFormikValidation = (formMap, values) => {
  const errors = {};

  for (let [key, value] of formMap) {
    if (!values[key]) {
      errors[key] = "O campo " + value + " é obrigatório."
    }
  }

  return errors;
};