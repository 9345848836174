import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const ViagemPage = lazy(() =>
    import("./viagem/ViagemPage")
);

const FaturaPage = lazy(() =>
    import("./fatura/FaturaPage")
);

const ContainerPage = lazy(() =>
    import("./container/ContainerPage")
);

export default function HomePage() {

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    <Redirect exact from="/" to="/viagem" />
                }

                <Route path="/viagem" component={ViagemPage} />
                <Route path="/fatura" component={FaturaPage} />
                <Route path="/container" component={ContainerPage} />

                <Redirect to="/error/error-v6" />
            </Switch>
        </Suspense>
    );
}
